import React from "react"
import {
  FaBtc,
  FaLinkedin,
  FaGithubSquare,
  FaMedium,
  FaTwitterSquare,
  FaCertificate,
} from "react-icons/fa"


const data = [
  {
    id: 1,
    icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
    url: "https://www.twitter.com/hiiiokay",
  },
  {
    id: 2,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/in/franciscoandaur/",
  },
  {
    id: 3,
    icon: <FaGithubSquare className="social-icon"></FaGithubSquare>,
    url: "https://github.com/FranciscoAndaur",
  },
  {
    id: 4,
    icon: <FaMedium className="social-icon"></FaMedium>,
    url: "https://medium.com/@franciscoandaurleiva",
  },
  {
    id: 5,
    icon: <FaBtc className="social-icon"></FaBtc>,
    url: "https://www.coinbase.com/join/leiva_6",
  },
  // {
  //   id: 6,
  //   icon: <FaCertificate className="social-icon"></FaCertificate>,
  //   url: "https://learn.co/FranciscoAndaur",
  // },
]
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} target="_blank" className="social-link" rel="nofollow">
        {link.icon}
      </a>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}
